import {
  UserOutlined,
  ShopOutlined,
  LoginOutlined,
  ReadOutlined,
  MailOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { HELP_CENTER_URL } from '../../constants'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'
import { routes } from '../../routes'
import { Avatar } from '../general/Avatar'

export const MobileMenu = () => {
  const { idmToken, fetchCurrentUser } = useContext(UserContext)
  const { menuExpanded, toggleMenu } = useContext(GlobalContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)

  return (
    <Wrapper
      className={`${menuExpanded && 'expanded'} ${idmToken && userData && 'logged-in'}`}
      onClick={() => toggleMenu()}>
      <div className='nav-section'>
        <Link to='/collaboration-hub'>
          <ShopOutlined />
          Collaboration Hub
        </Link>
        <a
          className='link'
          href='https://creator.co/blog'
          target='_blank'
          rel='noopener noreferrer'>
          <ReadOutlined />
          Blog
        </a>
        <a
          className='link'
          href='https://creator.co/creator-contact'
          target='_blank'
          rel='noopener noreferrer'>
          <MailOutlined />
          Contact
        </a>
        <a className='link' href={HELP_CENTER_URL} target='_blank' rel='noopener noreferrer'>
          <QuestionCircleOutlined />
          Help Center
        </a>
      </div>

      <div className='nav-section'>
        <p className='heading'>
          Dashboard
          {idmToken && userData && (
            <Avatar
              size={30}
              initials={`${userData.firstName.charAt(0).toUpperCase()} ${userData.lastName
                .charAt(0)
                .toUpperCase()}`}
            />
          )}
        </p>
        {idmToken ? (
          routes.map(
            (route, i) =>
              (!route.internalBeta || userData?.role === 'administrator') && (
                <Link key={i} exact={route.exact} to={route.path} className={route.className}>
                  {route.icon}
                  {route.label}
                </Link>
              )
          )
        ) : (
          <>
            <Link to='/login'>
              <LoginOutlined />
              Log In
            </Link>
            <Link to='/signup'>
              <UserOutlined />
              Sign Up
            </Link>
          </>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 40px 0 0;
  position: fixed;
  right: -100%;
  z-index: 999;
  transition: 0.2s ease-in-out;
  overflow: auto;
  &.expanded {
    right: 0;
  }
  &.logged-in {
    padding: 70px 40px 0 0;
  }
  .nav-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .heading {
    font-family: 'Campton-Light', Verdana, sans-serif;
    border-bottom: 1px solid #666;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 0 5px 20px;
  }
  .link {
    color: ${props => props.theme.crcoMidnight};
    margin: 5px 0;
    padding: 5px 20px;
    font-size: 1rem;
    border-radius: 0 20px 20px 0;
    transition: 0.2s ease-in-out;
    img {
      height: 20px;
      margin-right: 10px;
    }
    &.active {
      background: #ecf6ff;
      color: ${props => props.theme.crcoTechBlue};
    }
    .anticon {
      margin-right: 10px;
    }
  }
  .creator-profile {
    margin: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 0 10px 10px 0;
    .header-text {
      background: ${props => props.theme.crcoOffWhite};
      width: 100%;
      padding: 5px 20px;
      margin: 0;
      border-bottom: 1px solid #ddd;
      border-radius: 0 10px 0 0;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
    a {
      margin-right: 5px;
      padding: 5px 20px 5px 50px;
      font-size: 0.9rem;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    display: none;
  }
`

const Link = styled(NavLink)`
  color: ${props => props.theme.crcoMidnight};
  margin: 5px 0;
  padding: 5px 20px;
  font-size: 1rem;
  border-radius: 0 20px 20px 0;
  transition: 0.2s ease-in-out;
  &.active {
    background: #ecf6ff;
    color: ${props => props.theme.crcoTechBlue};
  }
  &.logout {
    margin-top: 20px;
    border-top: 1px solid #e6e6e6;
    padding-top: 20px;
    border-radius: 0;
    color: #ff0000;
  }
  .anticon {
    margin-right: 10px;
  }
`
