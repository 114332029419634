import React, { useContext, useEffect } from 'react'
import Cookies from 'universal-cookie'

import { GlobalContext } from '../../contexts/GlobalContext'

export const Login = () => {
  const cookies = new Cookies()
  const { idm } = useContext(GlobalContext)

  useEffect(() => {
    cookies.set('creator-redirect-to', 'creator', {
      domain: window.location.hostname.split('.').slice(-2).join('.'),
    })
    idm?.authenticator?.login()
  })

  return <></>
}
