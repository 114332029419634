import './App.less'
import './assets/index.css'
import LogRocket from 'logrocket'
import { useEffect, lazy, useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import Cookies from 'universal-cookie'

import { Base } from './Base'
import { ErrorFallBack } from './components/general/ErrorFallBack'
import { Login } from './components/screens/Login'
import { Logout } from './components/screens/Logout'
import { NotFound } from './components/screens/NotFound'
import { Welcome } from './components/screens/onboarding/Welcome'
import { CHARGEBEE_SITE } from './constants'
import { GlobalContext } from './contexts/GlobalContext'
import { OptInContextWrapper } from './contexts/OptInContextWrapper'
import { theme } from './GlobalStyles'
import { SuperBase } from './SuperBase'

const AffiliateRedirect = lazy(() => import('./components/screens/AffiliateRedirect'))
const Campaigns = lazy(() => import('./components/screens/campaignHub/Campaigns'))
const OptInConfirmation = lazy(
  () => import('./components/screens/campaignHub/singleCampaign/OptInConfirmation')
)
const SingleCampaign = lazy(
  () => import('./components/screens/campaignHub/singleCampaign/SingleCampaign')
)
const Affiliate = lazy(() => import('./components/screens/dashboard/affiliate/Affiliate'))
const CampaignDashboard = lazy(() => import('./components/screens/dashboard/CampaignDashboard'))
const CreatorProfile = lazy(
  () => import('./components/screens/dashboard/creator-profile/CreatorProfile')
)
const CreatorProfileSettings = lazy(
  () => import('./components/screens/dashboard/creator-profile/CreatorProfileSettings')
)
const CreatorProfileSetup = lazy(
  () => import('./components/screens/dashboard/creator-profile/CreatorProfileSetup')
)
const DashboardHome = lazy(() => import('./components/screens/dashboard/DashboardHome'))
const SingleOptIn = lazy(() => import('./components/screens/dashboard/opt-ins/SingleOptIn'))
const ProfileSettings = lazy(() => import('./components/screens/dashboard/ProfileSettings'))
const Wishlist = lazy(() => import('./components/screens/dashboard/Wishlist'))

export const App = () => {
  const { idm } = useContext(GlobalContext)
  const cookies = new Cookies()
  let subdomain
  const appDomain = process.env.REACT_APP_SUBDOMAIN
  const host = window.location.host
  const details = window.navigator.userAgent
  const isMobile = /Android|webOS|iPhone|iPad/i.test(details)
  const arr = host
    .split('.')
    .slice(0, host.includes('localhost') ? -1 : (host.split('.').length - 1) * -1)
  if (arr.length > 0) subdomain = arr[0]

  const OptInRoutesWrapper = () => {
    return (
      <OptInContextWrapper>
        <Outlet />
      </OptInContextWrapper>
    )
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <SuperBase />,
      children: [
        { path: '/signup', element: <Login /> },
        { path: '/welcome', element: <Welcome /> },
        { path: '/login', element: <Login /> },
        { path: '/logout', element: <Logout /> },
        { path: '/affiliate-redirect/:id', element: <AffiliateRedirect /> },
        { path: '*', element: <NotFound /> },
        { path: '/collaboration-hub', element: <Campaigns /> },
        { path: '/campaign/:slug', element: <SingleCampaign /> }, //legacy, 2 routes for SingleCampaign
        { path: '/campaigns/:slug', element: <SingleCampaign /> },
        {
          path: '',
          element: <Base />,
          children: [
            {
              path: '',
              element: <OptInRoutesWrapper />,
              children: [
                { path: '', element: <DashboardHome /> },
                { path: 'my-dashboard', element: <DashboardHome /> },
                { path: 'my-campaigns', element: <CampaignDashboard /> },
                { path: 'my-campaigns/:id', element: <SingleOptIn /> },
              ],
            },
            { path: 'my-wishlist', element: <Wishlist /> },
            { path: 'affiliate', element: <Affiliate /> },
            { path: 'affiliate/:tab', element: <Affiliate /> },
            { path: 'my-settings', element: <ProfileSettings /> },
            { path: 'my-profile', element: <CreatorProfileSettings /> },
            { path: 'creator-profile-setup', element: <CreatorProfileSetup /> },
            {
              path: 'opt-in-confirmation/:id',
              element: <OptInConfirmation />,
            },
          ],
        },
      ],
    },
  ])

  useEffect(() => {
    window.Chargebee.init({
      site: CHARGEBEE_SITE,
      iframeOnly: isMobile ? true : false,
    })
    window.civchat = {
      apiKey: 'GnXIMO',
    }
  }, [isMobile])

  useEffect(() => {
    // for bypassing opt-in requirements (need to capture at high level in case user needs to sign up first)
    const urlParams = new URLSearchParams(window.location.search)
    const inviteCode = urlParams.get('inviteCode')

    if (inviteCode) localStorage.setItem('inviteCode', inviteCode)
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const debug = urlParams.get('debug')
    if (debug == 'true') {
      window.logRocketInitialized = true
      LogRocket.init('zbp1lp/creator-co', {
        requestSanitizer: request => {
          if (request.url.toLowerCase().indexOf('/login') !== -1) request.body.password = null
          return request
        },
      })
    }
  }, [])

  let params = new URL(document.location).searchParams
  if (params.get('utm_campaign'))
    cookies.set('utm_campaign', params.get('utm_campaign'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_medium'))
    cookies.set('utm_medium', params.get('utm_medium'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_source'))
    cookies.set('utm_source', params.get('utm_source'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_content'))
    cookies.set('utm_content', params.get('utm_content'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_term'))
    cookies.set('utm_term', params.get('utm_term'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary fallback={<ErrorFallBack />} showDialog={true}>
        {!subdomain ||
        appDomain === subdomain ||
        subdomain.includes('creatorco-creators') ||
        !isNaN(subdomain) ? (
          !!idm && <RouterProvider router={router} />
        ) : (
          <CreatorProfile subdomain={subdomain} />
        )}
      </ErrorBoundary>
      <ReactQueryDevtools />
    </ThemeProvider>
  )
}
